import React, { useEffect } from 'react';
import navLogo from "../../assets/kycLogo.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import toast from "react-hot-toast";

function WaitingScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const roomCode = location.state?.roomCode;
    const userEmail = location.state?.userEmail;

    if (!roomCode || !userEmail) {
      console.log(location.state);
      console.log("Room code or user email is missing!", roomCode, userEmail);
      return;
    }

    const roomRef = doc(db, "rooms", roomCode);

    const unsubscribe = onSnapshot(roomRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log("Room data:", data); // Log room data
        if (data.sessionEnded) {
          navigate('/session-ended');
          return;
        }
        if (!data.isStarted) {
          try {
            // Update the player's isActive status to true
            const roomDoc = await getDoc(roomRef);
            if (roomDoc.exists()) {
              const roomData = roomDoc.data();
              console.log("Room document data:", roomData); // Log document data
              const players = roomData.players || [];
              const playerIndex = players.findIndex(player => player.email === userEmail);
              console.log("Player index:", playerIndex); // Log player index
              if (playerIndex !== -1) {
                players[playerIndex].isActive = true;
                console.log("Updated player data:", players[playerIndex]); // Log updated player data
                await updateDoc(roomRef, { players });
                console.log("Player status updated to active.");
              } else {
                console.log("Player not found.");
              }
            }
            // Navigate to the room page
            navigate('/room', { state: { roomCode, email: userEmail } });
          } catch (error) {
            console.error("Error updating player status: ", error);
            toast.error("Error updating player status.");
          }
        }
      } else {
        console.log("No such document!");
      }
    }, (error) => {
      console.error("Error fetching document: ", error);
    });

    return () => unsubscribe();
  }, [navigate, location.state?.roomCode, location.state?.email]);

  return (
    <div className="outerContainer">
      <div className="gameHeader">
        <img
          className=""
          src={navLogo}
          alt="navLogo"
          width={"100px"}
          height={"100px"}
        />
      </div>

      <div className="gameSetting text-white fs-5 d-flex justify-content-center align-items-center" style={{ marginTop: '180px' }}>
        You Have Joined The Game. Waiting For the Host to Initiate The Game
      </div>
    </div>
  );
}

export default WaitingScreen;
