import React, { useContext, useEffect, useState } from "react";
import "./GameScreen.css";
import navLogo from "../../assets/kycLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import { AuthContext } from "../../contexts/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import Layout from "../Layout";

function GameScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(0);
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [sessionEnded, setSessionEnded] = useState(false);
  const [currentRoundDuration, setCurrentRoundDuration] = useState(0);

  const fetchRoundDuration = async () => {
    const roomRef = doc(db, "rooms", location.state.roomCode);
    try {
      const roomDoc = await getDoc(roomRef);
      if (roomDoc.exists()) {
        const data = roomDoc.data();
        const rounds = data.rounds || [];
        const lastRound = rounds[rounds.length - 1];

        if (lastRound && lastRound.duration) {
          const roundDuration = parseInt(lastRound.duration, 10) * 60;
          setCurrentRoundDuration(roundDuration);

          if (data.endTime) {
            const endTime = data.endTime.toDate().getTime();
            const currentTime = Date.now();
            const remainingTime = Math.max(0, endTime - currentTime);
            setSessionEndTime(endTime);
            setTimeLeft(Math.floor(remainingTime / 1000)); // Convert ms to seconds
          } else {
            const endTime = Date.now() + roundDuration * 1000; // in milliseconds
            await updateDoc(roomRef, { endTime: new Date(endTime) });
            setSessionEndTime(endTime);
            setTimeLeft(roundDuration);
          }
        } else {
          console.error("Invalid round duration:", lastRound.duration);
          setCurrentRoundDuration(0);
          setTimeLeft(0);
          await updateDoc(roomRef, { endTime: null });
        }
      } else {
        toast.error("No Such Room!");
      }
    } catch (error) {
      console.error("Error fetching round duration: ", error);
    }
  };

  useEffect(() => {
    fetchRoundDuration();
  }, [location.state.roomCode]);

  const handleEndTimer = async () => {
    const roomRef = doc(db, "rooms", location.state.roomCode);

    try {
      // Update sessionEnded status and endTime for all users
      setSessionEndTime(new Date().getTime());
      await updateDoc(roomRef, {
        // sessionEnded: true,
        endTime: new Date(),
      });
      toast.success("Timer stopped by the host.");
      // navigate("/");
    } catch (error) {
      toast.error("Error stopping the timer.");
      console.error("Error during handleEndTimer:", error);
    }
  };

  useEffect(() => {
    const roomRef = doc(db, "rooms", location.state.roomCode);

    const unsubscribe = onSnapshot(
      roomRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.endTime) {
            const endTime = data.endTime.toDate().getTime();
            const currentTime = Date.now();
            const remainingTime = Math.max(0, endTime - currentTime);
            setTimeLeft(Math.floor(remainingTime / 1000)); // Convert ms to seconds
            setSessionEndTime(endTime)
          } else {
            setTimeLeft(0);
          }
          setSessionEnded(data.sessionEnded);
          if (data.sessionEnded) {
            toast.error("Session has ended by the host.");
            navigate("/session-ended");
          }
        } else {
          toast.error("No such room!");
        }
      },
      (error) => {
        console.error("Error fetching document: ", error);
      }
    );

    return () => unsubscribe();
  }, [location.state.roomCode, navigate]);

  useEffect(() => {
    const roomRef = doc(db, "rooms", location.state.roomCode);

    const unsubscribe = onSnapshot(roomRef, (docSnapshot) => {
      // Handle changes here
      const data = docSnapshot.data();
      if (data && data.endTime) {
        // The 'endTime' field exists and has a value
        // Execute your custom function or logic here
        console.log('endTime changed:', data.endTime);
        // Call your function or perform any other action
      }
    });

    // Clean up the listener when component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionEndTime) {
        const currentTime = Date.now();
        const remainingTime = Math.max(0, sessionEndTime - currentTime);
        if (Math.floor(remainingTime / 1000 <= 0)) {
          setTimeLeft(0);
          clearInterval(interval);
        }
        setTimeLeft(Math.floor(remainingTime / 1000));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [sessionEndTime]);

  const handleJoinNextRound = async () => {
    if (!auth.currentUser) {
      navigate("/waiting-screen", { state: { ...location.state } });
      return;
    }

    const roomRef = doc(db, "rooms", location.state.roomCode);
    const playersWithRole = location.state.playersWithRole || [];
    const userEmail = location.state.userEmail;

    try {
      // Log the current state for debugging
      console.log("Current playersWithRole:", playersWithRole);
      console.log("User email:", userEmail);

      // Update the player's isActive status to true
      const updatedPlayersWithRole = playersWithRole.map((player) =>
        player.email === userEmail ? { ...player, isActive: true } : player
      );

      // Get the current list of players
      const currentRoomDoc = await getDoc(roomRef);
      const currentRoomData = currentRoomDoc.data();
      const currentPlayers = currentRoomData.players || [];

      // Update the players array to reflect isActive status
      const updatedPlayers = currentPlayers.map((player) =>
        player.email === userEmail ? { ...player, isActive: true } : player
      );

      console.log("Updated players array:", updatedPlayers);
      console.log("Updated playersWithRole array:", updatedPlayersWithRole);

      // Update Firestore document with both arrays
      await updateDoc(roomRef, {
        players: updatedPlayers,
        playersWithRole: updatedPlayersWithRole,
      });

      console.log("Successfully updated players in Firestore.");

      // Mark the round as not started
      await updateDoc(roomRef, { isStarted: false });

      // Fetch the next round's duration and update state
      await fetchRoundDuration();

      navigate("/gamesetup", { state: { ...location.state } });
    } catch (error) {
      toast.error("Error updating the round status.");
      console.error("Error during handleJoinNextRound:", error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  const percentage =
    currentRoundDuration > 0 ? (timeLeft / currentRoundDuration) * 100 : 0;
  const stripeWidth = Math.min(percentage, 100); // Ensure it doesn't exceed 100%

  const playersWithRole = location.state?.playersWithRole || [];
  const userEmail = location.state?.userEmail;
  const currentUser = playersWithRole.find(
    (player) => player.email === userEmail
  );
  const playerName = currentUser?.name || "host";
  const playerRole = currentUser?.role || "host";

  return (
    <Layout roomCode={location.state.roomCode}>
      <div className="gameSetting">
        {sessionEnded ? (
          <div className="text-center fs-3 text-white">
            The session has ended. Thank you for playing!
          </div>
        ) : (
          <>
            <div className="row g-3">
              <div className="col-sm-12 fs-4 text-white">Hi, {playerName}</div>
              {!auth.currentUser && (
                <div>
                  <div className="col-sm-12 fs-4 text-white">
                    YOUR ROLE IN THE GAME IS
                  </div>
                  <div className="col-sm-12 fs-3 text-white">
                    <div
                      style={{
                        width: "250px",
                        backgroundColor: "green",
                        color: "black",
                      }}
                      className="text-white p-2 mx-auto text-center mt-5"
                    >
                      {auth.currentUser ? "Host" : playerRole.toUpperCase()}
                    </div>
                  </div>
                </div>
              )}
              <div
                className="col-sm-12 fs-3 text-white"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="stripe-timer-container">
                  <div
                    className="stripe-timer"
                    style={{ width: `${stripeWidth}%` }}
                  />
                </div>
                <div
                  className="stripe-timer-text"
                  style={{ marginBottom: "50px" }}
                >
                  {timeLeft > 0
                    ? `Time Left: ${formatTime(timeLeft)}`
                    : "Time Over"}
                </div>
                {timeLeft > 0 && auth.currentUser != null && (
                  <button
                    className="gradient-Background"
                    style={{ width: "200px" }}
                    onClick={handleEndTimer}
                  >
                    Stop Timer
                  </button>
                )}
                {timeLeft === 0 && (
                  <div className="d-flex justify-content-center align-items-center fs-5 text-white">
                    {!auth.currentUser ? (
                      <button
                        className="gradient-Background"
                        style={{ width: "200px" }}
                        onClick={handleJoinNextRound}
                      >
                        Join Next Round
                      </button>
                    ) : (
                      <button
                        className="gradient-Background"
                        onClick={handleJoinNextRound}
                      >
                        New Round
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default GameScreen;
