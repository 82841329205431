import { useContext } from "react";
import toast from "react-hot-toast";
import { FcCancel } from "react-icons/fc";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import navLogo from "../assets/kycLogo.png";
import { auth, db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

const Layout = ({children, roomCode}) => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      if(roomCode){
          const roomRef = doc(db, "rooms", roomCode);
          await updateDoc(roomRef, { sessionEnded: true });
      }
      toast.success("Successfully logged out");
      navigate("/host");
    } catch (error) {
      toast.error("Logout failed: " + error.message);
    }
  };

  return (
    <div className="outerContainer">
      <div className="gameHeader">
        <img
          className=""
          src={navLogo}
          alt="navLogo"
          width={"100px"}
          height={"100px"}
        />
        <div style={{ marginTop: "2vh" }}>
          {auth.currentUser ? (
            <button className="end-session" onClick={handleLogout}>
              <FcCancel size={"1.2rem"} style={{ marginRight: "0.3rem" }} /> End
              Session
            </button>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
