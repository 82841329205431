import React, { useContext, useEffect, useState } from "react";
import "./GameSetup.css";
import toast from "react-hot-toast";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { FcCancel } from "react-icons/fc";
import Layout from "../Layout";

function GameSetup() {
  const [noOfSpies, setNoOfSpies] = useState("");
  const [duration, setDuration] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [newCustomer, setNewCustomer] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // Access location state using the hook

  function generateCode(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setRoomCode(result);
  }

  async function createRoom() {
    // Validation
    if (noOfSpies === "" || duration === "" || selectedCustomer === "") {
      alert(
        "Please select all required fields: Number of Spies, Game Time, and Customer."
      );
      return;
    }
    try {
      const roomRef = doc(db, "rooms", roomCode);
      const roomSnapshot = await getDoc(roomRef);

      if (roomSnapshot.exists()) {
        console.log("Room with this code already exists");
        navigate("/room", {
          state: {
            noOfSpies,
            duration,
            selectedCustomer,
            roomCode,
          },
        });
        return;
      }

      await setDoc(roomRef, {
        hostId: auth.currentUser.uid,
        roomCode: roomCode,
        players: [],
        rounds: [],
        isStarted: false,
      });

      console.log("Room created with custom ID:", roomCode);

      navigate("/room", {
        state: {
          noOfSpies,
          duration,
          selectedCustomer,
          roomCode,
        },
      });
    } catch (error) {
      console.error("Error creating room:", error);
    }
  }

  function copyRoomCode(e) {
    const code = e.target.textContent;
    const input = document.createElement("input");
    input.value = code;
    document.body.append(input);
    input.select();
    document.execCommand("copy");
    input.remove();
    toast.success("Code copied");
  }


  async function getCustomerList() {
    try {
      const querySnapshot = await getDocs(collection(db, "customers"));
      const customers = [];
      querySnapshot.forEach((doc) => {
        customers.push(...doc.data().names);
      });
      setCustomerList(customers);
    } catch (error) {
      console.error("Error fetching customer list: ", error);
    }
  }

  async function insertCustomer() {
    try {
      if (newCustomer.trim() === "") {
        alert("Please enter a proper value");
        return;
      }

      const querySnapshot = await getDocs(collection(db, "customers"));
      if (querySnapshot.empty) {
        console.log("No documents found");
        return;
      }

      const firstDoc = querySnapshot.docs[0];
      const docId = firstDoc.id;

      const docRef = doc(db, "customers", docId);
      await updateDoc(docRef, {
        names: arrayUnion(newCustomer),
      });

      const updatedDocSnapshot = await getDoc(docRef);
      if (updatedDocSnapshot.exists()) {
        const updatedData = updatedDocSnapshot.data();
        if (Array.isArray(updatedData.names)) {
          setCustomerList(updatedData.names);
        } else {
          console.log("Expected names to be an array");
        }
        setNewCustomer("");
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }

  useEffect(() => {
    getCustomerList();
    if (location?.state?.roomCode) {
      setRoomCode(location?.state?.roomCode);
      return;
    }
    generateCode(7);
  }, [location]);

  return (
    <Layout>
      <div className="gameSetting">
        <div className="row g-3 ">
          <div className="col-sm-12 gameSettingText">Game Settings</div>
          <div className="col-sm-12 p-2">
            <div className="roomCode">
              Room Code:{" "}
              <label
                className="ml-3 rounded p-2 gradient-text roomCode"
                onClick={(e) => copyRoomCode(e)}
              >
                {roomCode}
              </label>
            </div>
          </div>
          <div className="col-md-6 p-2 ">
            <div className="mx-md-5 ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className="text-white labelTextStyle">
                  No of Spies:
                </label>
                <select
                  id="noOfSpies"
                  name="noOfSpies"
                  className="input-style-game"
                  value={noOfSpies}
                  onChange={(e) => setNoOfSpies(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {[1, 2].map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-2 ">
            <div className="mx-md-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className="text-white labelTextStyle">
                  Duration (In mins)
                </label>
                <select
                  id="duration"
                  name="duration"
                  className="input-style-game"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-2">
            <div className="mx-md-5 ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className="text-white labelTextStyle">
                  Select Customer
                </label>
                <select
                  id="customer"
                  name="customer"
                  className="input-style-game"
                  value={selectedCustomer}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {customerList.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-2">
            <div className="mx-md-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  id="newCustomerName"
                  className="input-style-game"
                  style={{cursor:'text'}}
                  placeholder="Type a New Customer"
                  value={newCustomer}
                  onChange={(e) => setNewCustomer(e.target.value)}
                />
                <button name="addCustomerButton" className="buttonStyle" onClick={insertCustomer}>
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center align-items-center fs-5 text-white mt-5 mb-5">
            <button
              className="gradient-Background"
              onClick={() => createRoom()}
            >
              Select Spy
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GameSetup;
