import React, { useState, useContext, useEffect } from "react";
import logo from "../../assets/kycLogo.png";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "../../contexts/AuthContext"; // Adjust the path as needed

function HostLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate("/gamesetup");
    }
  }, [currentUser, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, username, password)
        .then((e) => {
          toast.success("Successfully logged in");
          navigate("/gamesetup");
        })
        .catch((error) => {
          toast.error("Invalid credentials!");
        });
    } catch (error) {
      toast.error("Login failed!")
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="container">
      <div className="inside-container">
        <div className="imageContainer">
          <img className="imgDimensions " src={logo} alt="kyc" />
        </div>
        <p className="whiteText bold">How well do you know your customer?</p>
        <p className="whiteText bottomBorder padding">LOGIN TO YOUR ACCOUNT</p>
        <form action="#" className="" onSubmit={handleLogin}>
          <div className="username">
            <label
              htmlFor="username"
              style={{ display: "inline-block", marginLeft: "2%" }}
              className="whiteText padding"
            >
              Email
            </label>
            <input
              className="input-style"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter Your Email ID"
            />
          </div>
          <div className="password ">
            <label
              className="whiteText padding"
              htmlFor="password"
              style={{ display: "inline-block", marginLeft: "2%" }}
            >
              Password
            </label>
            <input
              className="input-style"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter the Password"
            />
          </div>
          <div className="submit margin rowFlexDirection">
            <button
              type="submit"
              className="button"
              style={{ maxWidth: "200px" }}
            >
             Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HostLogin;
