import React, { useEffect } from "react";
import navLogo from "../../assets/kycLogo.png";
import { useNavigate } from "react-router-dom";
function SessionEnded() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 4000);
  }, []);

  return (
    <div className="outerContainer">
      <div className="gameHeader">
        <img
          className=""
          src={navLogo}
          alt="navLogo"
          width={"100px"}
          height={"100px"}
        />
      </div>

      <div
        className="gameSetting  text-white fs-5 d-flex justify-content-center align-items-center "
        style={{ marginTop: "180px" }}
      >
        SESSION ENDED.. THANKS FOR PLAYING!
      </div>
    </div>
  );
}

export default SessionEnded;
