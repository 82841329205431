// src/App.jsx
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HostLogin from "./screens/hostlogin/HostLogin.jsx";
import './App.css';
import PlayersLogin from './screens/playerslogin/PlayersLogin.jsx';
import GameSetup from './screens/gamesetup/GameSetup.jsx';
import Room from './screens/room/Room.jsx';
import Game from './screens/gamescreen/GameScreen.jsx';
import { AuthProvider } from './contexts/AuthContext.jsx';
import SessionEnded from './screens/sessions/SessionEnded.jsx';
import WaitingScreen from './screens/waitingscreeen/WaitingScreen.jsx';
import ProtectedRoute from './ProtectedRoute.jsx';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="appContainer">
          <Toaster position='top-right' />
          <Routes>
            {/* Routes accessible without authentication */}
            <Route path="/" element={<PlayersLogin />} />
            <Route path="/room" element={<Room />} />
            <Route path="/game" element={<Game />} />
            <Route path="/host" element={<HostLogin />} />
            <Route path="/session-ended" element={<SessionEnded />} />
            <Route path="/waiting-screen" element={<WaitingScreen />} />

            {/* Protected routes */}
            <Route 
              path="/gamesetup" 
              element={<ProtectedRoute element={GameSetup} />} 
            />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
