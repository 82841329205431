import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import logo from "../../assets/kycLogo.png";
import { getDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase";
import toast from "react-hot-toast";

function PlayersLogin() {
  const [name, setName] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [email, setEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!name || !gameCode || !email) {
      console.log("Please fill in all fields");
      return;
    }

    const docRef = doc(db, "rooms", gameCode);

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const players = data.players || [];

        const emailExists = players.some((player) => player.email === email);

        if (emailExists) {
          toast.error("Email already exists in the room");
          return;
        } else {
          await updateDoc(docRef, {
            players: arrayUnion({ name, email, isActive: true }),
          });
          console.log("Document updated successfully");
          setIsLoggedIn(true); // Set login state to true after successful login
        }
      } else {
        toast.error("Room doesnt exist");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  if (isLoggedIn) {
    return (
      <Navigate
        to="/room"
        state={{
          roomCode: gameCode,
          email,
          name,
        }}
      />
    );
  }

  return (
    <div className="container">
      <div className="inside-container">
        <div className="imageContainer">
          <img className="imgDimensions " src={logo} alt="kyc" />
        </div>
        <p className="whiteText bold">How well do you know your customer?</p>
        <p className="whiteText bottomBorder padding">JOIN THE GAME</p>
        <div className="form-container">
          <form
            onSubmit={handleLogin}
            style={{ maxWidth: "400px", justifyContent: "center" }}
          >
            <div className="name">
              <label
                htmlFor="name"
                style={{ display: "inline-block", marginLeft: "2%" }}
                className="whiteText padding "
              >
                Name
              </label>
              <input
                className="input-style"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </div>
            <div className="gameCode">
              <label
                className="whiteText padding "
                htmlFor="gameCode"
                style={{ display: "inline-block", marginLeft: "2%" }}
              >
                Game Code
              </label>
              <input
                className="input-style"
                type="text"
                value={gameCode}
                onChange={(e) => setGameCode(e.target.value)}
                placeholder="Enter Game Code"
              />
            </div>
            <div className="emailId">
              <label
                className="whiteText padding "
                htmlFor="emailId"
                style={{ display: "inline-block", marginLeft: "2%" }}
              >
                Email ID
              </label>
              <input
                className="input-style"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </div>
            <div className="submit margin rowFlexDirection">
              <button
                type="submit"
                className="button"
                style={{ maxWidth: "200px" }}
              >
                Join Room
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PlayersLogin;
