// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKOZXVA-_QJwNNyAAyOoiaRB4WaVHCdkM",
  authDomain: "madiee-kyc.firebaseapp.com",
  databaseURL: "https://madiee-kyc-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "madiee-kyc",
  storageBucket: "madiee-kyc.appspot.com",
  messagingSenderId: "76850880198",
  appId: "1:76850880198:web:4c7fd74c6deb0c463181c7",
  measurementId: "G-NENC2ZJMWD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
export default app;
