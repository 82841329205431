import React, { useContext, useEffect, useState } from "react";
import "./Room.css";
import toast from "react-hot-toast";
import { doc, updateDoc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Layout from "../Layout";

function Room() {
  const location = useLocation();
  const navigate = useNavigate();
  const [spyIndexes, setSpyIndexes] = useState([]);
  const [selectedSpies, setSelectedSpies] = useState([]);
  const [roomData, setRoomData] = useState({ players: [] });
  const userEmail = location.state?.email;
  const spyCount = location.state.noOfSpies;

  useEffect(() => {
    // Set a flag to indicate that the page is being loaded
    sessionStorage.setItem("pageLoaded", "true");
  }, []);

  useEffect(() => {
    const roomRef = doc(db, "rooms", location.state.roomCode);

    const unsubscribe = onSnapshot(
      roomRef,
      async (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setRoomData(data);

          // Check if the game has started
          if (data.isStarted) {
            // Get the last round information
            const latestRound = data.rounds?.[data.rounds.length - 1];

            // Navigate to the /game page with the latest round data
            navigate("/game", {
              state: {
                duration: location.state.duration,
                roomCode: location.state.roomCode,
                playersWithRole: latestRound?.playersWithRole || [],
                userEmail: location.state.email,
              },
            });
          }

          // Check if the session has ended
          if (data.sessionEnded) {
            // Navigate to the /session-ended page
            navigate("/session-ended");
          }
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching document: ", error);
      }
    );

    return () => unsubscribe();
  }, [location.state.roomCode, navigate]);

  const updatePlayerStatus = async () => {
    console.log("updatePlayerStatus called");

    if (userEmail) {
      console.log(`Querying for email: ${userEmail}`);

      try {
        const roomDocRef = doc(db, "rooms", location.state.roomCode);
        const roomDoc = await getDoc(roomDocRef);

        if (roomDoc.exists()) {
          const data = roomDoc.data();
          const players = data.players || [];

          // Find the player with the specified email
          const playerIndex = players.findIndex(
            (player) => player.email === userEmail
          );

          if (playerIndex !== -1) {
            // Update the player's isActive status
            players[playerIndex].isActive = false;

            // Update the document with the modified array
            await updateDoc(roomDocRef, { players });
            console.log("Player status updated successfully.");
          } else {
            console.log("No player found with the specified email.");
          }
        } else {
          console.log("Room document does not exist.");
        }
      } catch (error) {
        console.error("Error updating player status: ", error);
      }
    } else {
      console.error("No user email provided.");
    }
  };

  useEffect(() => {
    const handleUnload = (event) => {
      const isReload = sessionStorage.getItem("pageLoaded") === "true";

      // Clear the flag to avoid future detections
      sessionStorage.removeItem("pageLoaded");

      if (userEmail && !isReload) {
        event.preventDefault();
        event.returnValue = ""; // Most browsers require returnValue to be set

        // Update the player status when leaving
        updatePlayerStatus();

        // Optionally use sendBeacon() for reliable data sending
        navigator.sendBeacon(
          "/your-endpoint",
          JSON.stringify({ email: userEmail })
        );
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [userEmail]);

  const selectSpies = (type, index) => {
    if (roomData.players.length === 0) return;
    const numberOfSpies = spyCount;
    console.log(roomData.players);
    const totalPlayers = roomData.players.length;
    const selectedIndexes = new Set();
    if (type == "random") {
      if (numberOfSpies > totalPlayers) return;
      while (selectedIndexes.size < numberOfSpies) {
        selectedIndexes.add(Math.floor(Math.random() * totalPlayers));
      }
      const spies = Array.from(selectedIndexes).map((i) => roomData.players[i]);
      setSpyIndexes(Array.from(selectedIndexes));
      setSelectedSpies(spies);
    } else {
      if (numberOfSpies == selectedSpies.length) return;
      setSpyIndexes([...spyIndexes, index]);
      setSelectedSpies([...selectedSpies, roomData.players[index]]);
    }
  };

  const removeSpy = (email) => {
    setSpyIndexes(
      spyIndexes.filter(
        (ind) => ind != roomData.players.findIndex((e, i) => e.email == email)
      )
    );
    setSelectedSpies(selectedSpies.filter((e) => e.email != email));
  };

  function copyRoomCode(e) {
    const code = e.target.textContent.slice(1, e.target.textContent.length - 1);
    const input = document.createElement("input");
    input.value = code;
    document.body.append(input);
    input.select();
    document.execCommand("copy");
    input.remove();
    toast.success("Code copied");
  }

  async function handleStartPlay() {
    if (roomData?.players.length === 0) {
      toast.error("No players in the room");
      return;
    }

    const filtered = roomData?.players.filter((val) => val.isActive);
    const result = filtered.map((val, i) =>
      spyIndexes.includes(i)
        ? { name: val.name, email: val.email, role: "Spy" }
        : {
            name: val.name,
            email: val.email,
            role: location.state.selectedCustomer,
          }
    );

    const roundsInfo = {
      roundNo: (roomData.rounds?.length || 0) + 1,
      playersWithRole: result,
      noOfSpies: location.state.noOfSpies,
      customer: location.state.selectedCustomer,
      duration: location.state.duration,
    };

    try {
      // Reference to the room document
      const roomDocRef = doc(db, "rooms", location.state.roomCode);

      // First, update the rounds information and set isStarted to true
      await updateDoc(roomDocRef, {
        rounds: [...(roomData.rounds || []), roundsInfo],
        isStarted: true,
        endTime: new Date(Date.now() + location.state.duration * 60 * 1000), // Set endTime
      });

      console.log("Round information updated successfully.");

      // Then, update the player statuses
      const roomDoc = await getDoc(roomDocRef);
      if (roomDoc.exists()) {
        const data = roomDoc.data();
        const players = data.players || [];

        // Set all players' isActive to false
        const updatedPlayers = players.map((player) => ({
          ...player,
          isActive: false,
        }));

        // Update the document with the modified players array
        await updateDoc(roomDocRef, { players: updatedPlayers });

        console.log("All player statuses updated to inactive.");
      } else {
        console.log("Room document does not exist.");
        toast.error("Room does not exist.");
        return;
      }

      toast.success("Game started successfully!");

      // Pass the players' roles and the user's email to the game route
      navigate("/game", {
        state: {
          duration: location.state.duration,
          roomCode: location.state.roomCode,
          playersWithRole: result,
          userEmail: auth.currentUser.email,
        },
      });
    } catch (error) {
      console.error("Error updating room: ", error);
      toast.error("Error starting the game.");
    }
  }

  return (
    <Layout roomCode={location.state.roomCode}>
      <div className="gameSetting">
        <div className="row g-3">
          <div className="col-sm-12 text-white text-center fs-4">
            TO JOIN THE GAME, GO TO KYC.MAIDEE.COM AND ENTER THE CODE{" "}
            <span
              className="gradient-text fs-4"
              onClick={(e) => copyRoomCode(e)}
            >
              '{`${location.state.roomCode}`}'
            </span>
          </div>
          <div className="col-sm-12 text-white text-center">
            <div className="text-center fs-4">
              {roomData
                ? roomData?.players.filter((val) => {
                    return val.isActive;
                  }).length
                : 0}{" "}
              Players Joined
            </div>
          </div>

          <div className="col-sm-12 p-2">
            <div className="mx-md-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="customerText pb-2">Players</div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 p-2 ">
            <div className="player-details">
              <div className="category-list">
                <ul>
                  {roomData
                    ? roomData?.players
                        .filter((val) => {
                          return val.isActive;
                        })
                        .map((val, i) => {
                          return auth.currentUser != null ? (
                            <li
                              key={val.key}
                              style={{
                                cursor:
                                  spyIndexes.includes(i) ||
                                  selectedSpies.length == spyCount
                                    ? "not-allowed"
                                    : `pointer`,
                              }}
                              className={`player-set`}
                              onClick={() => {
                                if (
                                  !spyIndexes.includes(i) &&
                                  selectedSpies.length < spyCount
                                ) {
                                  selectSpies("manual", i);
                                }
                              }}
                            >
                              {`${val.name}`}
                            </li>
                          ) : (
                            <li key={val.key} className={`player`}>
                              {`${val.name}`}
                            </li>
                          );
                        })
                    : null}
                </ul>
              </div>
            </div>
          </div>
          {auth.currentUser != null && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <span
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  width: "6rem",
                  display: "inline-block",
                  padding: "3px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={() => {
                  selectSpies("random", -1);
                }}
              >
                Random
              </span>
            </div>
          )}
          {auth.currentUser != null && (
            <div>
              <div className="col-sm-12 p-2">
                <div className="mx-md-5">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="customerText pb-2">Spies</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 p-2 ">
                <div className="player-details">
                  <div className="category-list subjects">
                    <ul>
                      {selectedSpies &&
                        selectedSpies.map((val, i) => {
                          return auth.currentUser != null ? (
                            <li
                              key={val.key}
                              style={{ cursor: `pointer` }}
                              className={`player-unset`}
                              onClick={() => removeSpy(val.email)}
                            >
                              {`${val.name}`}
                            </li>
                          ) : (
                            <li key={val.key} className={`player`}>
                              {`${val.name}`}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-12 text-center pt-4" style={{marginBottom: '6rem'}}>
            {auth.currentUser && (
              <button className="gradient-Background" onClick={handleStartPlay}>
                Start Game
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Room;
