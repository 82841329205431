
import React from 'react';
import { Navigate } from 'react-router-dom';// Update the path if needed
import { auth } from './firebase';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = auth.currentUser !== null; // Check if the user is authenticated

  return (
    isAuthenticated ? (
      <Component {...rest} />
    ) : (
      <Navigate to="/" /> // Redirect to home page or login page if not authenticated
    )
  );
};

export default ProtectedRoute;
